/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import footerLogo from "../assets/images/logo/footer-logo.svg";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import settingsService from "../services/settings";
import { mail, map } from "../constant/images";
import policyService from "../services/policy";

const Footer = () => {
  const updatedDate = new Date().getFullYear();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [settings, setSettings] = useState();

  const [informationPolicies, setInformationPolicies] = useState([]);

  const getSettings = async () => {
    const data = await settingsService.getAll();
    console.log("fetched data", data);
    setSettings(data?.data[0]);
  };

  const getInformationPolicy = async () => {
    const data = await policyService.getPolicy();
    console.log("fetched data", data);
    if (data?.data) {
      setInformationPolicies(data?.data);
    }
  };

  useEffect(() => {
    getSettings();
    getInformationPolicy();
  }, []);

  return (
    <footer className="bg-black bg-[url('../images/all-img/footer-bg-1.png')] bg-cover bg-center bg-no-repeat mt-20">
      <div className="section-padding container">
        <div className="grid grid-cols-1  md:grid-cols-2 lg:grid-cols-4">
          <div className="single-footer">
            <div className="lg:max-w-[270px]">
              <a href="#" className="mb-10 block">
                <img src={footerLogo} alt="" />
              </a>
              <p>
                Indian Institute of Career Development empowers individuals with
                career-focused training, practical skills, and industry-aligned
                programs for success.
              </p>
              <ul className="flex space-x-4 pt-8">
                {settings?.facebookLink && (
                  <li>
                    <a
                      href={settings?.facebookLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex h-12 w-12 flex-col items-center justify-center rounded bg-white bg-opacity-[0.08] text-2xl text-white
                  transition hover:bg-primary hover:text-white"
                    >
                      <iconify-icon icon="bxl:facebook"></iconify-icon>
                    </a>
                  </li>
                )}
                {settings?.twitterLink && (
                  <li>
                    <a
                      href={settings?.twitterLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex h-12 w-12 flex-col items-center justify-center rounded bg-white bg-opacity-[0.08] text-2xl text-white
                  transition hover:bg-primary hover:text-white"
                    >
                      <iconify-icon icon="bxl:twitter"></iconify-icon>
                    </a>
                  </li>
                )}
                {settings?.linkedInLink && (
                  <li>
                    <a
                      href={settings?.linkedInLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex h-12 w-12 flex-col items-center justify-center rounded bg-white bg-opacity-[0.08] text-2xl text-white
                  transition hover:bg-primary hover:text-white"
                    >
                      <iconify-icon icon="bxl:linkedin"></iconify-icon>
                    </a>
                  </li>
                )}
                {settings?.instagramLink && (
                  <li>
                    <a
                      href={settings?.instagramLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex h-12 w-12 flex-col items-center justify-center rounded bg-white bg-opacity-[0.08] text-2xl text-white
                  transition hover:bg-primary hover:text-white"
                    >
                      <iconify-icon icon="bxl:instagram"></iconify-icon>
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>
          <div className="single-footer">
            <div className="flex space-x-[80px]">
              <div className="flex-1 lg:flex-none relative left-1/2 -translate-x-1/2">
                <h4 className="mb-8 text-footer font-bold text-white">Links</h4>
                <ul className="list-item space-y-5">
                  <li className="cursor-pointer">
                    <a onClick={() => navigate("/")}>Home</a>
                  </li>
                  {/* <li className="cursor-pointer">
                    <a onClick={() => navigate("/about")}>About Us</a>
                  </li> */}
                  <li className="cursor-pointer">
                    <a onClick={() => navigate("/courses")}>Courses</a>
                  </li>
                  <li className="cursor-pointer">
                    <a onClick={() => navigate("/contacts")}>Contact Us</a>
                  </li>
                </ul>
              </div>
              {/* <div className="flex-1 lg:flex-none">
                <h4 className="mb-8 text-footer font-bold text-white">Legal</h4>
                <ul className="list-item space-y-5">
                  <li>
                    <a >Legal</a>
                  </li>
                  <li>
                    <a >Tearms of Use</a>
                  </li>
                  <li>
                    <a >Tearm & Condition</a>
                  </li>
                  <li>
                    <a >Payment Method</a>
                  </li>
                  <li>
                    <a >Privacy Policy</a>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
          <div className="single-footer">
            <div className="flex space-x-[80px]">
              <div className="flex-1 lg:flex-none relative left-1/2 -translate-x-1/2">
                <h4 className="mb-8 text-footer font-bold text-white">
                  Policies
                </h4>
                <ul className="list-item space-y-5">
                  {informationPolicies &&
                    informationPolicies?.map((item) => (
                      <li className="cursor-pointer">
                        <a
                          onClick={() =>
                            navigate(`/information-policy/${item.slug}`)
                          }
                        >
                          {item.data?.title}
                        </a>
                      </li>
                    ))}
                </ul>
              </div>
              {/* <div className="flex-1 lg:flex-none">
                <h4 className="mb-8 text-footer font-bold text-white">Legal</h4>
                <ul className="list-item space-y-5">
                  <li>
                    <a >Legal</a>
                  </li>
                  <li>
                    <a >Tearms of Use</a>
                  </li>
                  <li>
                    <a >Tearm & Condition</a>
                  </li>
                  <li>
                    <a >Payment Method</a>
                  </li>
                  <li>
                    <a >Privacy Policy</a>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
          <div className="single-footer">
            <h4 className="mb-8 text-footer font-bold text-white">
              Contact us
            </h4>
            {/* <div className="mb-8">
              Join thousands of professionals receiving exclusive updates,
              expert tips, and career growth resources.
            </div>
            <div className="mb-4 flex items-center rounded-md bg-white py-[10px] pr-[10px] pl-6 shadow-e1">
              <div className="flex-none">
                <span className=" ">
                  <img src="assets/images/icon/mail.svg" alt="" />
                </span>
              </div>
              <div className="flex-1">
                <input
                  type="text"
                  placeholder="Enter your mail"
                  className="border-none focus:ring-0"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div> */}
            {/* <button
              onClick={() => {
                if (email == "") {
                  message.error({
                    content: "Please Enter Email",
                    duration: 5, // Duration in seconds
                  });
                } else {
                  message.success({
                    content: "Submitted Successfully",
                    duration: 5, // Duration in seconds
                  });
                  setEmail("");
                }
              }}
              className="btn btn-primary block w-full text-center"
            >
              Email Us
            </button> */}
            <ul class=" list-item space-y-6">
              <li class="flex">
                {/* <div class="flex-none mr-6">
                  <div class="">
                    <img src={mail} alt="" class="" />
                  </div>
                </div> */}
                <div class="flex-1">
                  <h4 class=" lg:text-xl text-lg mb-1 text-white">
                    Email-Us :
                  </h4>
                  <div
                    className="normal-case wrap-text"
                    style={{ textTransform: "none" }}
                  >
                    {settings?.email}
                  </div>
                </div>
              </li>
              {/* <li class="flex">
                  <div class="flex-none mr-6">
                    <div class="">
                      <img src={call} alt="" class="" />
                    </div>
                  </div>
                  <div class="flex-1">
                    <h4 class=" lg:text-xl text-lg mb-1">Call Us:</h4>
                    <div>+88012 2910 1781, +88019 6128 1689</div>
                  </div>
                </li> */}
              <li class="flex">
                {/* <div class="flex-none mr-6">
                  <div class="">
                    <img src={map} alt="" class="" />
                  </div>
                </div> */}
                <div class="flex-1">
                  <h4 class="lg:text-xl text-lg mb-1 text-white">Office :</h4>
                  <div className="address-text">{settings?.address}</div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container border-t border-white border-opacity-[0.1] py-8 text-center text-base">
        &copy; Copyright {updatedDate} | v1.1.3 |{" "}
        <a href="https://riolabz.com/" target="_blank" rel="noreferrer">
          Riolabz
        </a>{" "}
        | All Rights Reserved
      </div>
    </footer>
  );
};

export default Footer;
