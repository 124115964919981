/* eslint-disable jsx-a11y/anchor-is-valid */
import { Tab } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  c1,
  c2,
  c3,
  c4,
  c5,
  c6,
  c7,
  clock,
  file,
  review,
  star,
  reviewStars,
  singleStar,
  halfStar,
  emptyStar,
  playButton,
} from "../../../../constant/images";
import LoadingSpinner from "../../../../assets/svgs/LoadingSpinner";
import Pagination from "../../../Pagination/Pagination";
import SEO from "../../../SEO/SEO";
import ReactPlayer from "react-player";
import { Modal } from "antd";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";

const FilteredGallery = ({
  classNameForTabOne,
  classNameForTabTwo,
  data,
  loading,
}) => {
  const listIcon = [
    "clarity:grid-view-line",
    "ant-design:unordered-list-outlined",
  ];

  const [showAllImage, setShowAllImage] = useState(false);
  const [showAllVideo, setShowAllVideo] = useState(false);
  const courses = [1, 2, 3, 4, 5, 6, 7, 8];
  const [sliceAmount, setSliceAmount] = useState(6);
  const [sliceAmountVideo, setSliceAmountVideo] = useState(6);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState();

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (showAllImage) {
      setSliceAmount(courses?.length);
    } else {
      setSliceAmount(6);
    }
  }, [showAllImage]);

  useEffect(() => {
    if (showAllVideo) {
      setSliceAmountVideo(courses?.length);
    } else {
      setSliceAmountVideo(6);
    }
  }, [showAllVideo]);

  const handlePlayClick = () => {
    setIsModalOpen(true);
  };

  return (
    <>

      <Tab.Group>
        <div className="flex flex-wrap gap-5 justify-center  items-center mb-14"></div>
        {loading &&  <LoadingSpinner />}
        {!loading && data?.name == 0 && (
          <h4 className="widget-title text-center mt-10">No Data Found</h4>
        )}
        {!loading && data?.name && (
          <Tab.Panels as="div" id="tabs-content">
            <Tab.Panel as="div" id="tab1" className="tab-content">
              <h1 className="gallery-detail-title">Images</h1>
              <div className={classNameForTabOne}>
              <PhotoProvider>
                {data?.images?.slice(0, sliceAmount)?.map((item, index) => (
                  <Link
                    className=" bg-white shadow-box2 rounded-[8px] transition duration-100 hover:shadow-sm"
                    // to={`/gallery-detail/${item?.slug}`}
                    key={`${item._id}${index}`}
                  >
                    <div
                      onClick={() => {
                        localStorage.setItem("selectedCourse", item?.slug);
                      }}
                      className="course-thumb h-auto rounded-[8px] relative"
                    >
                       <PhotoView key={index} src={item?.image}>
                      <img
                        src={item?.image}
                        alt=""
                        className=" w-full h-auto object-cover rounded-[8px]"
                      />
                      </PhotoView>
                    </div>
                  </Link>
                ))}
                </PhotoProvider>
              </div>
              <div className="pt-5 w-full flex justify-end mb-3">
                <a
                  className=" btn btn-primary inline-flex items-center  space-x-[10px] cursor-pointer"
                  onClick={() => {
                    setShowAllImage(!showAllImage);
                  }}
                >
                  <span>{showAllImage ? "Hide" : "View All"}</span>
                </a>
              </div>
              <h1 className="gallery-detail-title">Videos</h1>
              <div>
                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px]">
                  {data?.videos
                    ?.slice(0, sliceAmountVideo)
                    ?.map((item, index) => {
                      // Extract the video thumbnail for YouTube videos
                      const getYouTubeThumbnail = (url) => {
                        const videoIdMatch = url.match(
                          /(?:https?:\/\/(?:www\.)?youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|(?:https?:\/\/)?(?:www\.)?youtu\.be\/)([a-zA-Z0-9_-]{11})/
                        );
                        return videoIdMatch
                          ? `https://img.youtube.com/vi/${videoIdMatch[1]}/hqdefault.jpg`
                          : null;
                      };

                      const videoThumbnail =
                        // getYouTubeThumbnail(blog?.videoUrl) || blog.profileImage;
                        getYouTubeThumbnail(item);

                      return (
                        <div
                          className="bg-white shadow-box5 rounded-[8px] transition duration-100 hover:shadow-box3"
                          key={index}
                        >
                          <div className="course-thumb h-auto rounded-t-[8px] relative">
                            <img
                              src={videoThumbnail}
                              alt=""
                              className="w-full h-auto object-cover rounded-[8px]"
                            />
                            <img
                              class="cursor-pointer absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-blue-500 text-white p-4 rounded-lg"
                              src={playButton}
                              onClick={() => {
                                setVideoUrl(item);
                                handlePlayClick();
                              }}
                            />
                          </div>
                        </div>
                      );
                    })}
                  <Modal
                    open={isModalOpen}
                    onCancel={handleModalClose}
                    footer={null}
                    centered
                    width="800px"
                  >
                    <ReactPlayer
                      url={videoUrl}
                      controls
                      playing={isModalOpen} // Auto-play when modal is open
                      width="100%"
                      height="450px"
                    />
                  </Modal>
                </div>
              </div>
              <div className="pt-5 w-full flex justify-end mb-3">
                <a
                  className=" btn btn-primary inline-flex items-center  space-x-[10px] cursor-pointer"
                  onClick={() => {
                    setShowAllVideo(!showAllVideo);
                  }}
                >
                  <span>{showAllVideo ? "Hide" : "View All"}</span>
                </a>
              </div>
            </Tab.Panel>
            <Tab.Panel id="tab2" className="tab-content">
              <div className={classNameForTabTwo}>
                {[c1, c2, c3, c4, c5, c6, c7].map((item, index) => (
                  <Link
                    className=" bg-white rounded-[8px] transition shadow-box7 duration-150 border-b-4 hover:border-primary border-transparent
            hover:shadow-box6 flex p-8 space-x-6"
                    to={"/single-course"}
                    key={index}
                  >
                    <div className="flex-none">
                      <div className="w-[159px] h-[159px]  rounded  relative">
                        <img
                          src={item}
                          alt=""
                          className=" w-full h-full object-cover rounded"
                        />
                      </div>
                    </div>
                    <div className="course-content flex-1">
                      <div className="text-primary font-bold text-2xl mb-2 flex justify-between">
                        <span className=" inline-block">$29.28</span>
                        <span className=" flex space-x-1">
                          <span className="w-4 h-4 inline-block ">
                            <img
                              src={review}
                              alt=""
                              className=" w-full h-full block object-cover"
                            />
                          </span>
                          <span className="w-4 h-4 inline-block ">
                            <img
                              src={review}
                              alt=""
                              className=" w-full h-full block object-cover"
                            />
                          </span>
                          <span className="w-4 h-4 inline-block ">
                            <img
                              src={review}
                              alt=""
                              className=" w-full h-full block object-cover"
                            />
                          </span>
                          <span className="w-4 h-4 inline-block ">
                            <img
                              src={review}
                              alt=""
                              className=" w-full h-full block object-cover"
                            />
                          </span>
                        </span>
                      </div>
                      <h4 className=" text-2xl leading-[36px] mb-4 font-bold">
                        Basic Fundamentals of Interior &amp; Graphics Design
                      </h4>
                      <div className="flex   space-x-6">
                        <span className=" flex items-center space-x-2">
                          <img src="assets/images/svg/file2.svg" alt="" />
                          <span>2 Lessons</span>
                        </span>
                        <span className=" flex items-center space-x-2">
                          <img src="assets/images/svg/user2.svg" alt="" />
                          <span>4k Lesson</span>
                        </span>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
              <div className="text-center pt-14">
                <a
                  href="#"
                  className=" btn btn-primary inline-flex items-center  space-x-[10px]"
                >
                  <span>Load More</span>
                  <span className=" relative top-1">
                    <iconify-icon icon="ion:reload-outline"></iconify-icon>
                  </span>
                </a>
              </div>
            </Tab.Panel>
          </Tab.Panels>
        )}
      </Tab.Group>
    </>
  );
};

export default FilteredGallery;
