import React from 'react';
import ReactDOM from 'react-dom/client';
import './constant/importedCss'
import App from './App';
import { CourseProvider } from './CourseProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <CourseProvider>
  <React.StrictMode> 
    <App />
  </React.StrictMode>
  </CourseProvider>
);

