import fetch from './FetchInterceptor'

const galleryService = {}
const apiRoute = '/gallery';

galleryService.getAll = async function (query = '') {
    try {
        const res = await fetch({
            url: `${apiRoute}/getAll/public${query}`,
            method: 'get'
        });
        return res;
    } catch (err) {
        console.log(err, 'show-err');
        return err
    }
}

galleryService.getById = async function (id) {
    try {
      const res = await fetch({
        url: `${apiRoute}/getById/public/${id}`,
        method: 'get',
      })
      return res
    } catch (err) {
      console.log(err, 'show-err')
    }
  }

  galleryService.getBySlug = async function (slug) {
    try {
      const res = await fetch({
        url: `${apiRoute}/get/slug/${slug}`,
        method: 'get',
      })
      return res
    } catch (err) {
      return err
      console.log(err, 'show-err')
    }
  }

  export default galleryService
  
