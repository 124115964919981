/* eslint-disable jsx-a11y/anchor-is-valid */
import { Tab } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { c1,
  c2,
  c3,
  c4,
  c5,
  c6,
  c7,
  clock,
  file,
  review,
  star,
  reviewStars,
  singleStar,
  halfStar,
  emptyStar, } from "../../../constant/images";
import { useCourseContext } from "../../../CourseProvider";
import galleryService from "../../../services/gallery";
import LoadingSpinner from "../../../assets/svgs/LoadingSpinner";
import Pagination from "../../Pagination/Pagination";
import SEO from "../../SEO/SEO";



const FilteredGallery = ({ classNameForTabOne, classNameForTabTwo }) => {
  const listIcon = [
    "clarity:grid-view-line",
    "ant-design:unordered-list-outlined",
  ];

  const { selectedCourse, selectCourse } = useCourseContext();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  //pagination

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const page = params.get("page");
  const limit = params.get("limit");
  const searchParam = params.get("search");

  const [pagination, setPagination] = useState({
    current: Number(page),
    pageSize: Number(limit),
  });
  const [currentPage, setCurrentPage] = useState(page ? Number(page) : 1);
  const [totalCount, setTotalCount] = useState(0);
  const pageSize = 9;

  // useEffect(() => {
  //   //initial page loading adding the default page and limit values to url
  //   if (!page || !limit) {
  //     params.set("page", 1);
  //     params.set("limit", pageSize);
  //     navigate(`${location.pathname}?${params.toString()}`, {
  //       replace: true,
  //     });
  //     setPagination({
  //       current: 1,
  //       pageSize: pageSize,
  //     });
  //   }
  // }, [params]);

  const onPageChange = () => {
    params.set("page", currentPage);
    params.set("limit", pageSize);
    navigate(`${location.pathname}?${params.toString()}`, {
      replace: true,
    });
  };

  useEffect(() => {
    if (page != currentPage) {
      onPageChange();
    }
  }, [currentPage]);

  const getGallery = async () => {
    setLoading(true);
    try {
      const data = await galleryService.getAll(
        `?sortByPriority=true`
        // `?page=${page}&limit=${limit}`
      );

      console.log('fetched final', data)

      // Handle 404 directly here
      if (data?.code == "ERR_BAD_RESPONSE" || data?.code == "ERR_BAD_REQUEST"){
        setData([]);
        setLoading(false);
        return; // Exit early
      }

      console.log("Fetched data:", data);
      if (data?.data) {
        setData(data?.data);
        setTotalCount(data?.total);
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      setData([]);
      console.log(err, "show-err in courses");
      
    }
  };

  console.log('courses and loading', data, loading)

  useEffect(() => {
    if (page && limit) {
      getGallery();
    }
  }, [location.search]); // The empty dependency array ensures the effect runs only once

  return (
    <>
    <SEO />
    <Tab.Group>
      <div className="flex flex-wrap gap-5 justify-center  items-center mb-14">
      </div>
      {loading && !data?.length > 0 && <LoadingSpinner />}
      {!loading && data?.length == 0 && (
        <h4 className="widget-title text-center mt-10">No Data Found</h4>
      )}
      {!loading && data?.length > 0 && (
        <Tab.Panels as="div" id="tabs-content">
          <Tab.Panel as="div" id="tab1" className="tab-content">
            <div className={classNameForTabOne}>
              {data?.map((item, index) => (
                <Link
                  className=" bg-white shadow-box2 rounded-[8px] transition duration-100 hover:shadow-sm"
                  to={`/gallery-detail/${item?._id}`}
                  key={`${item._id}${index}`}
                >
                  <div
                    onClick={() => {
                      localStorage.setItem("selectedCourse", item?.slug);
                      selectCourse(item.url);
                    }}
                    className="course-thumb h-auto rounded-t-[8px]  relative"
                  >
                    <img
                      src={item?.images?.find((item)=>item?.mainImage == "yes")?.image}
                      alt=""
                      className=" w-full h-auto object-cover rounded-t-[8px]"
                    />
                  </div>
                  <div className="course-content p-8">
                    <h4 className=" text-xl mb-3 font-bold h-[60px] truncate-lines">
                      {item?.name}
                    </h4>
                    {/* <div className="flex justify-between items-center mb-3"> */}
                    {/* <div className="text-secondary font-bold text-2xl ">
                    {item.price}
                  </div> */}
                    {/* <img src={reviewStars} className="w-[150px]" /> */}
                    {/* <StarRating rating={4.5} /> */}
                    {/* </div> */}

                    {/* <div className="flex justify-between  flex-wrap space-y-1 xl:space-y-0">
                    <span className=" flex items-center space-x-2 mr-3">
                      <img src={file} alt="" />
                      <span>2 Lessons</span>
                    </span>
                    <span className=" flex items-center space-x-2 mr-3">
                      <img src={clock} alt="" />
                      <span> {item.time} </span>
                    </span>
                    <span className=" flex items-center space-x-2 ">
                      <img src={star} alt="" />
                      <span>{item.ratings}</span>
                    </span>
                  </div> */}
                  </div>
                </Link>
              ))}
            </div>
            {/* <div className="text-center pt-14">
            <a
              href="#"
              className=" btn btn-primary inline-flex items-center  space-x-[10px]"
            >
              <span>Load More</span>
              <span className=" relative top-1">
                <iconify-icon icon="ion:reload-outline"></iconify-icon>
              </span>
            </a>
          </div> */}
          </Tab.Panel>
          <Tab.Panel id="tab2" className="tab-content">
            <div className={classNameForTabTwo}>
              {[c1, c2, c3, c4, c5, c6, c7].map((item, index) => (
                <Link
                  className=" bg-white rounded-[8px] transition shadow-box7 duration-150 border-b-4 hover:border-primary border-transparent
            hover:shadow-box6 flex p-8 space-x-6"
                  to={"/single-course"}
                  key={index}
                >
                  <div className="flex-none">
                    <div className="w-[159px] h-[159px]  rounded  relative">
                      <img
                        src={item}
                        alt=""
                        className=" w-full h-full object-cover rounded"
                      />
                    </div>
                  </div>
                  <div className="course-content flex-1">
                    <div className="text-primary font-bold text-2xl mb-2 flex justify-between">
                      <span className=" inline-block">$29.28</span>
                      <span className=" flex space-x-1">
                        <span className="w-4 h-4 inline-block ">
                          <img
                            src={review}
                            alt=""
                            className=" w-full h-full block object-cover"
                          />
                        </span>
                        <span className="w-4 h-4 inline-block ">
                          <img
                            src={review}
                            alt=""
                            className=" w-full h-full block object-cover"
                          />
                        </span>
                        <span className="w-4 h-4 inline-block ">
                          <img
                            src={review}
                            alt=""
                            className=" w-full h-full block object-cover"
                          />
                        </span>
                        <span className="w-4 h-4 inline-block ">
                          <img
                            src={review}
                            alt=""
                            className=" w-full h-full block object-cover"
                          />
                        </span>
                      </span>
                    </div>
                    <h4 className=" text-2xl leading-[36px] mb-4 font-bold">
                      Basic Fundamentals of Interior &amp; Graphics Design
                    </h4>
                    <div className="flex   space-x-6">
                      <span className=" flex items-center space-x-2">
                        <img src="assets/images/svg/file2.svg" alt="" />
                        <span>2 Lessons</span>
                      </span>
                      <span className=" flex items-center space-x-2">
                        <img src="assets/images/svg/user2.svg" alt="" />
                        <span>4k Lesson</span>
                      </span>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
            <div className="text-center pt-14">
              <a
                href="#"
                className=" btn btn-primary inline-flex items-center  space-x-[10px]"
              >
                <span>Load More</span>
                <span className=" relative top-1">
                  <iconify-icon icon="ion:reload-outline"></iconify-icon>
                </span>
              </a>
            </div>
          </Tab.Panel>
        </Tab.Panels>
      )}
      {/* {!loading && data?.name > 0 && (
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalItemsCount={totalCount}
          itemsCountPerPage={pageSize}
        />
      )} */}
    </Tab.Group>
    </>
  );
};

export default FilteredGallery;
