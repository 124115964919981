/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import FilteredCourse from "./FilteredCourse";
import Header from "../../Header";
import PageBanner from "../../PageBanner";
import Footer from "../../Footer";
import {
  rating1,
  rating2,
  rating3,
  rating4,
  rating5,
  search,
  whiteCheck,
} from "../../../constant/images";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingSpinner from "../../../assets/svgs/LoadingSpinner";
import pagesService from "../../../services/pages";
import Pagination from "../../Pagination/Pagination";
import { courseData } from "../../../constant/dummyData";
import SEO from "../../SEO/SEO";

const CorporateSolutions = () => {
  const [corporateData, setCorporateData] = useState([]);
  const [courses, setCourses] = useState([]);
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState();
  const [corporateLoading, setCorporateLoading] = useState(true);
  const [courseLoading, setCourseLoading] = useState(true);
  //pagination

  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const page = params.get("page");
  const limit = params.get("limit");
  const searchParam = params.get("search");

  const [pagination, setPagination] = useState({
    current: Number(page),
    pageSize: Number(limit),
  });
  const [currentPage, setCurrentPage] = useState(page ? Number(page) : 1);
  const [totalCount, setTotalCount] = useState(0);
  const pageSize = 6;

  useEffect(() => {
    //initial page loading adding the default page and limit values to url
    if (!page || !limit) {
      params.set("page", 1);
      params.set("limit", pageSize);
      navigate(`${location.pathname}?${params.toString()}`, {
        replace: true,
      });
      setPagination({
        current: 1,
        pageSize: pageSize,
      });
    }
  }, [params]);

  const onPageChange = () => {
    params.set("page", currentPage);
    params.set("limit", pageSize);
    navigate(`${location.pathname}?${params.toString()}`, {
      replace: true,
    });
  };

  useEffect(() => {
    if (page != currentPage) {
      onPageChange();
    }
  }, [currentPage]);

  const getCorporateData = async () => {
    setCorporateLoading(true);

    const queryParams = new URLSearchParams({
      category: "6759416fea264a6404d9e127",
      priority: "true",
      page,
      limit,
    });

    if (searchInput && searchInput != "") {
      queryParams.append("search", searchInput);
    }

    try {
      const data = await pagesService.getAll(`?${queryParams.toString()}`);

      if (data?.code == "ERR_BAD_RESPONSE" || data?.code == "ERR_BAD_REQUEST") {
        // navigate("/not-found");
        setCorporateData([]);
        setCorporateLoading(false);
        return; // Exit early
      }

      console.log("Fetched data:", data);
      if (data?.data) {
        setCorporateData(data?.data);
        setTotalCount(data?.total);
        setCorporateLoading(false);
      }
    } catch (err) {
      console.log(err, "show-err");
      setCorporateLoading(false);
    }
  };

  const getCourses = async () => {
    setCourseLoading(true);
    try {
      const data = await pagesService.getAll(
        `?category=674df5891beff24912948432&priority=true&page=1&limit=9`
      );

      if (data?.code == "ERR_BAD_RESPONSE" || data?.code == "ERR_BAD_REQUEST") {
        // navigate("/not-found");
        setCourses([]);
        setCourseLoading(false);
        return; // Exit early
      }

      console.log("Fetched data:", data);
      if (data?.data) {
        setCourses(data?.data);
        setCourseLoading(false);
      }
    } catch (err) {
      console.log(err, "show-err");
      setCourseLoading(false);
    }
  };

  useEffect(() => {
    if (page && limit) {
      getCorporateData();
    }
  }, [location.search]);

  useEffect(() => {
    getCourses();
  }, []); // The empty dependency array ensures the effect runs only once

  return (
    <>
      <SEO />
      <Header />
      <PageBanner title="Corporate Solutions" pageTitle="Corporate Solutions" />
      <div className="nav-tab-wrapper tabs pt-10 section-padding-bottom">
        <div className="container">
          <div className="grid grid-cols-12 gap-[30px]">
            <div className="lg:col-span-8 col-span-12">
              {corporateLoading && !corporateData?.length > 0 && (
                <LoadingSpinner />
              )}
              {!corporateLoading && corporateData?.length == 0 && (
                <h4 className="widget-title text-center mt-10">
                  No Data Found
                </h4>
              )}
              {!corporateLoading && corporateData?.length > 0 && (
                <FilteredCourse
                  classNameForTabOne={
                    "grid md:grid-cols-2 grid-cols-1 gap-[30px]"
                  }
                  classNameForTabTwo={"grid  grid-cols-1 gap-[30px]"}
                  related="Corporate Solutions"
                  courses={corporateData}
                />
              )}
              {!corporateLoading && corporateData?.length > 0 && (
                <Pagination
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  totalItemsCount={totalCount}
                  itemsCountPerPage={pageSize}
                />
              )}
            </div>
            <div class="lg:col-span-4 col-span-12 mt-14">
              <SideBar
                courses={courses}
                searchInput={searchInput}
                setSearchInput={setSearchInput}
                courseLoading={courseLoading}
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CorporateSolutions;

const SideBar = ({ courses, searchInput, setSearchInput, courseLoading }) => {
  const [priceVal, setPriceVal] = useState(0);

  const navigate = useNavigate();

  return (
    <div className="lg:col-span-4 col-span-12">
      <div className="sidebarWrapper space-y-[30px]">
        <div className="wdiget widget_search">
          <div className="bg-[#F8F8F8] flex  rounded-md shadow-e1 items-center  py-[4px] pl-3  relative">
            <div className="flex-1">
              <input
                type="text"
                placeholder="Search..."
                onChange={(e) => setSearchInput(e.target.value)}
                className="border-none focus:ring-0 bg-transparent"
              />
            </div>
            <div className="flex-none">
              <button
                className="btn btn-primary"
                onClick={() => {
                  if (searchInput && searchInput != "") {
                    navigate(
                      `/corporate-solutions?page=1&limit=10&search=${searchInput}`
                    );
                  }
                }}
              >
                <img src={search} alt="" />
              </button>
            </div>
          </div>
        </div>
        {/* <div className="wdiget widget_catagory">
        <h4 className="widget-title">Categories</h4>

        <ul className=" list-item space-y-4">
          <li className=" block">
            <a
              href="#"
              className=" flex justify-between bg-[#F8F8F8] py-[17px] px-5 rounded hover:bg-primary hover:text-white transition-all
              duration-150"
            >
              <span>Development (23)</span>
              <span class="text-2xl">
                <iconify-icon icon="heroicons:chevron-right-20-solid"></iconify-icon>
              </span>
            </a>
          </li>

          <li className=" block">
            <a
              href="#"
              className=" flex justify-between bg-[#F8F8F8] py-[17px] px-5 rounded hover:bg-primary hover:text-white transition-all
              duration-150"
            >
              <span>Art &amp; Design (45)</span>
              <span class="text-2xl">
                <iconify-icon icon="heroicons:chevron-right-20-solid"></iconify-icon>
              </span>
            </a>
          </li>

          <li className=" block">
            <a
              href="#"
              className=" flex justify-between bg-[#F8F8F8] py-[17px] px-5 rounded hover:bg-primary hover:text-white transition-all
              duration-150"
            >
              <span>Data Science (14)</span>
              <span class="text-2xl">
                <iconify-icon icon="heroicons:chevron-right-20-solid"></iconify-icon>
              </span>
            </a>
          </li>

          <li className=" block">
            <a
              href="#"
              className=" flex justify-between bg-[#F8F8F8] py-[17px] px-5 rounded hover:bg-primary hover:text-white transition-all
              duration-150"
            >
              <span>Data Science (14)</span>
              <span class="text-2xl">
                <iconify-icon icon="heroicons:chevron-right-20-solid"></iconify-icon>
              </span>
            </a>
          </li>

          <li className=" block">
            <a
              href="#"
              className=" flex justify-between bg-[#F8F8F8] py-[17px] px-5 rounded hover:bg-primary hover:text-white transition-all
              duration-150"
            >
              <span>Technology (28)</span>
              <span class="text-2xl">
                <iconify-icon icon="heroicons:chevron-right-20-solid"></iconify-icon>
              </span>
            </a>
          </li>

          <li className=" block">
            <a
              href="#"
              className=" flex justify-between bg-[#F8F8F8] py-[17px] px-5 rounded hover:bg-primary hover:text-white transition-all
              duration-150"
            >
              <span>It Management (34)</span>
              <span class="text-2xl">
                <iconify-icon icon="heroicons:chevron-right-20-solid"></iconify-icon>
              </span>
            </a>
          </li>
        </ul>
      </div> */}
        <div className="wdiget widget-recent-post">
          <h4 className=" widget-title">Our Courses</h4>
          <ul className="list">
            {courseLoading && !courses?.length > 0 && <LoadingSpinner />}
            {!courseLoading && courses?.length == 0 && (
              <h4 className="widget-title text-left mt-10">No Data Found</h4>
            )}
            {!courseLoading &&
              courses?.map((item) => {
                return (
                  <li
                    key={item._id}
                    className="cursor-pointer flex space-x-4 border-[#ECECEC] pb-6 mb-6 last:pb-0 last:mb-0 last:border-0 border-b"
                    onClick={() =>
                      navigate(`/corporate-solutions-detail/${item.slug}`)
                    }
                  >
                    <div className="flex-none ">
                      <div className="h-20 w-20  rounded">
                        <img
                          src={item.image}
                          alt=""
                          className=" w-full h-auto object-cover rounded"
                        />
                      </div>
                    </div>
                    <div className="flex-1 ">
                      <div className="mb-1 font-semibold text-black ellipsis">
                        {item.name}
                      </div>
                      <a className=" text-secondary font-semibold">Read More</a>
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
        {/* <div className="wdiget">
        <h4 className="widget-title">Popular Tags</h4>
        <ul className="flex flex-wrap">
          <li className="mr-2 mb-2">
            <a
              href="#"
              className="bg-[#F8F8F8] px-3 py-1 rounded text-base  transition-all  duration-150 hover:bg-primary hover:text-white"
            >
              Business
            </a>
          </li>

          <li className="mr-2 mb-2">
            <a
              href="#"
              className="bg-[#F8F8F8] px-3 py-1 rounded text-base  transition-all  duration-150 hover:bg-primary hover:text-white"
            >
              Education
            </a>
          </li>

          <li className="mr-2 mb-2">
            <a
              href="#"
              className="bg-[#F8F8F8] px-3 py-1 rounded text-base  transition-all  duration-150 hover:bg-primary hover:text-white"
            >
              Design
            </a>
          </li>

          <li className="mr-2 mb-2">
            <a
              href="#"
              className="bg-[#F8F8F8] px-3 py-1 rounded text-base  transition-all  duration-150 hover:bg-primary hover:text-white"
            >
              Students
            </a>
          </li>

          <li className="mr-2 mb-2">
            <a
              href="#"
              className="bg-[#F8F8F8] px-3 py-1 rounded text-base  transition-all  duration-150 hover:bg-primary hover:text-white"
            >
              Teachers
            </a>
          </li>

          <li className="mr-2 mb-2">
            <a
              href="#"
              className="bg-[#F8F8F8] px-3 py-1 rounded text-base  transition-all  duration-150 hover:bg-primary hover:text-white"
            >
              classNameroom
            </a>
          </li>

          <li className="mr-2 mb-2">
            <a
              href="#"
              className="bg-[#F8F8F8] px-3 py-1 rounded text-base  transition-all  duration-150 hover:bg-primary hover:text-white"
            >
              Online
            </a>
          </li>

          <li className="mr-2 mb-2">
            <a
              href="#"
              className="bg-[#F8F8F8] px-3 py-1 rounded text-base  transition-all  duration-150 hover:bg-primary hover:text-white"
            >
              e-Learning
            </a>
          </li>

          <li className="mr-2 mb-2">
            <a
              href="#"
              className="bg-[#F8F8F8] px-3 py-1 rounded text-base  transition-all  duration-150 hover:bg-primary hover:text-white"
            >
              Book
            </a>
          </li>
        </ul>
      </div> */}
        {/* <div className="wdiget">
        <h4 className="widget-title">Instagram Feed</h4>
        <div className="grid grid-cols-3 gap-4">
          {[
            insIcon1,
            insIcon2,
            insIcon3,
            insIcon4,
            insIcon5,
            insIcon6,
          ].map((item, index) => (
            <div key={index}>
              <a
                href="#"
                className="group relative block h-20 w-full rounded"
              >
                <img
                  src={item}
                  alt=""
                  className="block h-full w-full rounded object-cover"
                />
                <div
                  className="absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center rounded bg-black bg-opacity-40
                                          text-3xl text-white opacity-0 transition-all duration-150 group-hover:opacity-100"
                >
                  <span className="scale-0 transition-all duration-150 group-hover:scale-100">
                    <iconify-icon icon="akar-icons:instagram-fill"></iconify-icon>
                  </span>
                </div>
              </a>
            </div>
          ))}
        </div>
      </div> */}
      </div>
    </div>
  );
};
