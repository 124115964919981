/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo/logo.svg";
import MobileMenu from "./MobileMenu";
import ContactModal from "./Pages/ContactModal";

const Header = () => {
  const [activeMobileMenu, setActiveMobileMenu] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const scrollNav = useRef(null);
  useEffect(() => {
    // Scrolling navigation logic
    const handleScroll = () => {
      if (scrollNav.current) {
        let windowScroll = window.scrollY > 100;
        scrollNav?.current?.classList?.toggle("rt-sticky-active", windowScroll);
        scrollNav?.current?.classList?.toggle("sticky", windowScroll);
      }
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <header
        className="site-header  home-one-header top-0 w-full z-[999] rt-sticky "
        ref={scrollNav}
      >
        <div className="main-header py-6">
          <div className="container nav-container">
            <div className=" flex items-center justify-between">
              <Link
                to={"/"}
                className="brand-logo flex-none lg:mr-10 md:w-auto w-[250px] h-[60px] "
              >
                <img src={logo} alt="logo" />
              </Link>
              <div className="flex items-center flex-1">
                <div className="flex-1 main-menu relative mr-[10px]">
                  <ul className="menu-active-classNamees">
                    <li>
                      <Link to="/">
                        Home
                        {/* <a>Home</a> */}
                      </Link>
                      <ul className="sub-menu">
                        <li>
                          <Link to={"/"}> Home One</Link>
                        </li>
                        <li>
                          <Link to={"/home-two"}>Home Two</Link>
                        </li>
                        <li>
                          <Link to={"/home-three"}>Home Three</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to={"/courses"}>
                        Courses
                        {/* <a href="#">Courses</a> */}
                      </Link>
                      <ul className="sub-menu">
                        <li>
                          <Link to={"/courses"}>Courses</Link>
                        </li>
                        <li>
                          <Link to={"/courses-sidebar"}>Courses Sidebar</Link>
                        </li>
                        <li>
                          <Link to={"/single-course"}>Single Course</Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to={"/corporate-solutions"}>
                        Corporate Solutions
                        {/* <a href="#">Courses</a> */}
                      </Link>
                    </li>
                    <li>
                      <Link to={"/gallery"}>
                        Gallery
                        {/* <a href="#">Courses</a> */}
                      </Link>
                    </li>
                    {/* <li className="menu-item-has-children"> */}
                    {/* <li>
                      <Link to={"/about"}>
                        About
                      </Link> */}
                    {/* <ul className="sub-menu">
                        <li>
                          <Link to={"/about"}>
                            About Us
                          </Link>
                        </li>
                        <li>
                          <Link to={"/testimonials"}>
                            Testimonials
                          </Link>
                        </li> */}
                    {/* <li>
                          <Link to={"/react-templates/edumim/instructor"}>
                            Instructor
                          </Link>
                        </li>
                        <li>
                          <Link to={"/react-templates/edumim/instructor-two"}>
                            Instructor 2
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={"/react-templates/edumim/instructor-details"}
                          >
                            Instructor Single
                          </Link>
                        </li>
                        <li>
                          <Link to={"/react-templates/edumim/event"}>
                            Event
                          </Link>
                        </li>
                        <li>
                          <Link to={"/react-templates/edumim/event-single"}>
                            Event Single
                          </Link>
                        </li>
                        <li>
                          <Link to={"/react-templates/edumim/error"}>404</Link>
                        </li> */}
                    {/* </ul> */}
                    {/* </li> */}
                    <li className="menu-item-has-children">
                      <Link to={"/testimonials"}>Testimonials</Link>
                      <ul className="sub-menu">
                        <li>
                          <Link to={"/testimonials"}>Testimonials</Link>
                        </li>
                        <li>
                          <Link to={"/video-testimonials"}>
                            Video testimonials
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to={"/blogs"}>
                        <a href="#">Blogs</a>
                      </Link>
                      <ul className="sub-menu">
                        <li>
                          <Link to={"/react-templates/edumim/blog-standard"}>
                            Blog Standard
                          </Link>
                        </li>
                        <li>
                          <Link to={"/react-templates/edumim/single-blog"}>
                            Blog Single
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to={"/contacts"}>Contact</Link>
                    </li>
                  </ul>
                </div>
                <div className="flex-none flex space-x-[18px]">
                  <div
                    className=" hidden lg:block"
                    onClick={() => setIsModalOpen(true)}
                  >
                    <a href="#" className="btn btn-primary py-[15px] px-8">
                      Enquire Now
                    </a>
                  </div>
                  <div className=" block   lg:hidden">
                    <button
                      type="button"
                      className=" text-3xl md:w-[56px] h-10 w-10 md:h-[56px] rounded bg-[#F8F8F8] flex flex-col items-center justify-center
                                        menu-click"
                      onClick={() => setActiveMobileMenu(!activeMobileMenu)}
                    >
                      <iconify-icon
                        icon="cil:hamburger-menu"
                        rotate="180deg"
                      ></iconify-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {activeMobileMenu && (
        <MobileMenu
          activeMenu={activeMobileMenu}
          setActiveMenu={setActiveMobileMenu}
        />
      )}
      <ContactModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </>
  );
};

export default Header;
