import React from "react";

function FilesList({ files }) {

  const handleDownload = async (fileUrl,index) => {
    const fileName = `file_${index}`;

    try {
        // Fetch the file as a Blob
        const response = await fetch(fileUrl);

        if (!response.ok) {
            throw new Error("Failed to fetch the file.");
        }

        const blob = await response.blob();

        // Create a temporary URL for the Blob
        const url = window.URL.createObjectURL(blob);

        // Create a temporary <a> element
        const link = document.createElement("a");
        link.href = fileUrl;
        link.target = '_blank'
        link.download = fileName;

        // Programmatically click the link to trigger the download
        link.click();

        // Revoke the object URL to free memory
        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.error("Error downloading the file:", error);
    }
};


  return (
    <div className="mt-10">
      <h3 className=" text-2xl mb-3">Downloads</h3>
      <div className="flex items-start gap-4 flex-wrap">
        {files?.map((item, index) => (
          <div className="flex items-start gap-2">
          {/* <a target="_blank" href="" download={`file_${index}`}> */}
          <p
            // onClick={() => window.open(item, "_blank")}
            onClick={()=>handleDownload(item)}
            className="cursor-pointer"
            style={{color: '#91bbff'}}
          >
            File {index + 1}
          </p>
         
          {/* </a> */}
           {index != files?.length-1 && (
            <p>,</p>
            
          )}
          </div>
          
        ))}
      </div>
    </div>
  );
}

export default FilesList;
