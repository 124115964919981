import React, { useEffect, useState } from "react";
import PageBanner from "../../PageBanner";
import Header from "../../Header";
import Footer from "../../Footer";
import FilteredGallery from "./FilteredGallery";

const Gallery = () => {

  return (
    <>
      <Header />
      <PageBanner title={"Gallery"} pageName="Gallery" />
      <div className="nav-tab-wrapper tabs pt-10 section-padding-bottom">
        <div className="container">
          <FilteredGallery
            classNameForTabOne={
              "grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px]"
            }
            classNameForTabTwo={
              "grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 gap-[30px]"
            }
          />
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Gallery;
