import { Modal } from "antd";
import React from "react";
import useDeviceWidth from "../../UseDeviceWidth";

function PopUpCard({ isModalOpen, setIsModalOpen, data, image, setpopUpOnce }) {

  const width = useDeviceWidth();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    console.log("OK button clicked");
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    console.log("Cancel button clicked");
    setIsModalOpen(false);
    setpopUpOnce(1);
  };

  return (
    <div className="home-page-modal">
      <Modal
        // title="My Ant Design Modal"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={width > 700 ? 500 : "85%"}
        className="banner-modal"
        centered
      >
        <div className="home-modal">
          {data?.listingItems?.length != 0 && (
            <img className="w-full mt-3" src={image} />
          )}
          {data?.showTitle && <h1>{data?.title}</h1>}
          {data?.description && (
            <p className={!data?.showTitleitle && "mt-3"}>
              {data?.description}
            </p>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default PopUpCard;
