import axios from 'axios';
import { notification } from 'antd';

//test server
// const API_BASE_URL = 'https://course.api.riolabz.com/api/v1/';

//live server
const API_BASE_URL = 'https://api.indianinstituteofcareerdevelopment.com/api/v1/';

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 120000,
});

// Track the last time an error notification was shown
let lastErrorTime = 0;
const ERROR_DEBOUNCE_TIME = 300; // 1 second debounce time

// API Request interceptor
service.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    // Handle request error
    notification.error({
      message: 'Request Error',
      description: 'An error occurred while sending the request.',
    });
    return Promise.reject(error);
  }
);

// API Response interceptor
service.interceptors.response.use(
  (response) => {
    return response?.data; // Return only the data part of the response
  },
  (error) => {
    let notificationParam = {
      message: 'Something went wrong!',
    };

    // Get the current time
    const currentTime = Date.now();

    // If the last error notification was shown less than debounce time ago, skip this one
    if (currentTime - lastErrorTime < ERROR_DEBOUNCE_TIME) {
      return Promise.reject(error); // Do not show the notification if the debounce time hasn't passed
    }

    if (
      error.response?.config?.url.includes('/pages-seo/url/') &&
      error.response?.status === 404
    ) {
      // Return a default value or null
      return Promise.resolve(null); // Suppress error notification
    }

    // Handle specific error codes
    if (error.response?.status === 400) {
      notificationParam.message = error.response.data.message || 'Bad Request';
    }
    if (error.response?.status === 404) {
      notificationParam.message = error.response.data.message || 'Not Found';
    }
    if (error.response?.status === 409) {
      notificationParam.message = error.response.data.message || 'Conflict';
    }
    if (error.response?.status === 508) {
      notificationParam.message = 'Timeout';
    }

    // Generic error message for other status codes
    if (!error.response || !notificationParam.message) {
      notificationParam.message = 'An unexpected error occurred.';
    }

    // Display the error notification
    notification.error(notificationParam);

    // Update the last error time
    lastErrorTime = currentTime;

    return Promise.reject(error);
  }
);

export default service;
