import React, { useEffect, useState } from "react";
import FilteredGallery from "./FilteredGallery";
import pagesService from "../../../../services/pages";
import Header from "../../../Header";
import Footer from "../../../Footer";
import PageBanner from "../../../PageBanner";
import galleryService from "../../../../services/gallery";
import { useParams } from "react-router-dom";
import SEO from "../../../SEO/SEO";

const GalleryDetail = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const {galleryId} = useParams();
  const [metaData, setMetaData] = useState();

  const getGallery = async () => {
    setLoading(true);
    try {
      const data = await galleryService.getById(galleryId)

      console.log("fetched final", data);

      // Handle 404 directly here
      if (data?.code == "ERR_BAD_RESPONSE" || data?.code == "ERR_BAD_REQUEST") {
        setData([]);
        setLoading(false);
        return; // Exit early
      }

      console.log("Fetched data:", data);
      if (data?.data) {
        setData(data?.data);
        setLoading(false);
        setMetaData({
          pageTitle: data?.data?.metatitle,
          pageDescription: data?.data?.metadescription,
          pageKeywords: data?.data?.metakeywords?.join(","),
        });
      }
    } catch (err) {
      setLoading(false);
      setData([]);
      console.log(err, "show-err in courses");
    }
  };

  useEffect(() => {
    getGallery();
  }, []); // The empty dependency array ensures the effect runs only once

  return (
    <>
     {metaData && (
     <SEO seo={metaData && metaData} />
     )}
      <Header />
      <PageBanner
        title={data?.name}
        pageName={data?.name}
      />
      <div className="nav-tab-wrapper tabs pt-10 section-padding-bottom">
        <div className="container">
          <FilteredGallery
            data={data}
            loading = {loading}
            classNameForTabOne={
              "grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px]"
            }
            classNameForTabTwo={
              "grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 gap-[30px]"
            }
          />
        </div>
      </div>

      <Footer />
    </>
  );
};

export default GalleryDetail;
