import React, { useEffect, useState } from "react";
import About from "../About";
import Banner from "../Banner";
import Blogs from "../Blogs";
import Brands from "../Brands";
import Courses from "../Courses";
import Feature from "../Feature";
import Footer from "../Footer";
import Header from "../Header";
import NewsLetter from "../NewsLetter";
import Platform from "../Platform";
import Team from "../Team";
import Topics from "../Topics";
import Testimonial from "../HomeThreeComponents/TestimonialTwo";
import Videotestimonials from "../Pages/VideoTestimonials";
import WhyChoose from "../HomeOneComponents/WhyChoose";
import HomeTestimonials from "../HomeOneComponents/TestimonialTwo";
import HomeVideotestimonials from "../HomeOneComponents/VideoTestimonials";
import useDeviceWidth from "../UseDeviceWidth";
import SEO from "../SEO/SEO";
import PopUpCard from "../HomeOneComponents/popup-card/PopUpCard";

const HomeOne = ({ widgets, popUpOnce, setpopUpOnce }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openOnce, setOpenOnce] = useState(0);
  const [popUpData, setPopUpData] = useState({});
  const [imageFile, setImageFile] = useState();
  console.log("popup once value", popUpOnce);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  console.log("home page json data", widgets);
  const width = useDeviceWidth();

  useEffect(() => {
    const isPopup = widgets?.find((item) => item?.type == "Pop-up");
    const image =
      width > 700
        ? isPopup?.listingItems[0]?.webImage
        : isPopup?.listingItems[0]?.mobileImage;
    setImageFile(image);
    if (isPopup?.title && popUpOnce == 0) {
      setIsModalOpen(true);
      setPopUpData(isPopup);
    }
  }, [widgets]);

  return (
    <>
      <SEO />
      <Header />
      {widgets?.map((item) => {
        // Determine if the component should be shown based on device type
        const shouldShowOnDesktop = item?.deviceType?.includes("Desktop");
        const shouldShowOnMobile = item?.deviceType?.includes("Mobile");

        // Render based on the device type and the current device
        if (shouldShowOnDesktop && width > 600) {
          if (item.type === "Banner") {
            return <Banner data={item} />;
          } else if (
            item.type === "Page" &&
            item?.categoryName == "Content" &&
            item?.designType[0] == "ABOUT US"
          ) {
            return <About data={item} />;
          } else if (
            item.type === "Page" &&
            item?.categoryName == "Content" &&
            item?.designType == "WHY CHOOSE US"
          ) {
            return <WhyChoose data={item} />;
          } else if (item.type === "Client") {
            return <Brands data={item} />;
          } else if (item.type === "Page" && item?.categoryName == "Courses") {
            return <Courses data={item} />;
          } else if (item.type === "Page" && item?.categoryName == "Blogs") {
            return <Courses data={item} />;
          } else if (
            item.type === "Page" &&
            item?.categoryName == "Corporate Solutions"
          ) {
            return <Courses data={item} />;
          } else if (
            item.type === "Page" &&
            item?.categoryName == "Content" &&
            item?.designType[0] == "CAREERS"
          ) {
            return <Platform data={item} />;
          } else if (
            item.type === "Testimonial" &&
            item?.categoryName == "Testimonials"
          ) {
            return <HomeTestimonials data={item} />;
          } else if (
            item.type === "Testimonial" &&
            item?.categoryName == "Video Testimonials"
          ) {
            return <HomeVideotestimonials data={item} />;
          }
          // else if (
          //   item.type === "Pop-up"
          // ) {

          // }
          else if (item.type === "Static") {
            return (
              <div
                className="home-static"
                dangerouslySetInnerHTML={{ __html: item?.staticContent }}
              ></div>
            );
          } else {
            // return <div key={item._id}>Unknown Type</div>;
          }
        } else if (shouldShowOnMobile && width <= 700) {
          // Similar conditional rendering for mobile
          if (item.type === "Banner") {
            return <Banner data={item} />;
          } else if (
            item.type === "Page" &&
            item?.categoryName == "Content" &&
            item?.designType[0] == "ABOUT US"
          ) {
            return <About data={item} />;
          } else if (
            item.type === "Page" &&
            item?.categoryName == "Content" &&
            item?.designType == "WHY CHOOSE US"
          ) {
            return <WhyChoose data={item} />;
          } else if (item.type === "Client") {
            return <Brands data={item} />;
          } else if (item.type === "Page" && item?.categoryName == "Courses") {
            return <Courses data={item} />;
          } else if (item.type === "Page" && item?.categoryName == "Blogs") {
            return <Courses data={item} />;
          } else if (
            item.type === "Page" &&
            item?.categoryName == "Corporate Solutions"
          ) {
            return <Courses data={item} />;
          } else if (
            item.type === "Page" &&
            item?.categoryName == "Content" &&
            item?.designType[0] == "CAREERS"
          ) {
            return <Platform data={item} />;
          } else if (
            item.type === "Testimonial" &&
            item?.categoryName == "Testimonials"
          ) {
            return <HomeTestimonials data={item} />;
          } else if (
            item.type === "Testimonial" &&
            item?.categoryName == "Video Testimonials"
          ) {
            return <HomeVideotestimonials data={item} />;
          } else if (item.type === "Static") {
            return (
              <div
                className="home-static"
                dangerouslySetInnerHTML={{ __html: item?.staticContent }}
              ></div>
            );
          } else {
            // return <div key={item._id}>Unknown Type</div>;
          }
        } else {
          return null; // Do not render anything if the device type does not match
        }
      })}
      {/* <Header />
      <Banner />
      <About />
      <Brands section_padding_bottom={"section-padding-bottom"} />
      <Feature />
      <WhyChoose />
      <Courses />
      <Platform /> */}
      {/* <NewsLetter /> */}
      {/* <Topics /> */}
      {/* <Testimonials /> */}
      {/* <HomeTestimonials />
      <br />
      <br />
      <br />
      <HomeVideotestimonials /> */}
      {/* <Team />
      <Blogs /> */}
      <Footer />
      <PopUpCard
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        data={popUpData}
        image={imageFile ? imageFile : ""}
        setpopUpOnce={setpopUpOnce}
      />
    </>
  );
};

export default HomeOne;
